import equip1 from '../equipment/ddtsi-truck-trailer-hagerman.png'
import equip2 from '../equipment/ddtsi-truck-trailer-canyonbehind.png'
import equip3 from '../equipment/new-cascadia-18-wheel-corner-office.jpg'
import equip4 from '../equipment/front-refer.png'
import equip5 from '../equipment/utility-trailer-open.png'
import equip6 from '../equipment/cascadia-interior-top.png'


export const photos = [
  {
    src: equip1,
    width: 12,
    height: 4
  },
  {
    src: equip3,
    width: 10,
    height: 4
  },
  {
    src: equip4,
    width: 6,
    height: 4
  },
  {
    src: equip5,
    width: 12,
    height: 4
  },
  {
    src: equip6,
    width: 12,
    height: 4
  },
  {
    src: equip2,
    width: 16,
    height: 4
  }
];
