import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Gallery from "react-photo-gallery";
import { photos } from "../images/equipment/photos"

import {
  above,
  Zone,
  DownloadButton,
  Button,
  LargeP,
  SidebarLayout,
  InlineBox,
  BenefitsItem,
  BenefitsSidebarList,
  darkGrey,
  primary,
  secondary,
} from '../elements'
import backbone from '../images/backbone-nation-seal-black.svg'
import hero from '../images/slideshow/ddtsi-hagerman-id.png'
import { Link } from 'gatsby'
import { sansCondBold } from '../utilities'
import QuickApply from '../components/QuickApply'

const DriversPage = ({ data }) => {
  const postNode = {
    title: `Drivers - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Drivers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="drivers" customTitle />

      <Zone modifiers={['primary', 'short']} left hero image={hero} style={{ paddingBottom: `100px` }}>
        <div className="zone-content">
          <h1>Putting Drivers First</h1>
          <h3>
          We know that drivers are the backbone of our company, and to show we mean it, we’ve introduced a new pay scale to match or exceed competitive wages.
          </h3>
          <ReverseBanner>Earn $90,000 or more per year</ReverseBanner>

          <DownloadButton
            modifiers={['darkoutline', 'margintop']}
            rel="noopener noreferrer"
            href="https://intelliapp.driverapponline.com/c/ddtsi"
            target="_blank"
          >
            Full Application&nbsp;
            <i className="material-icons">call_made</i>
          </DownloadButton>
          <Button 
            to="#quickapply"
            modifiers={['dark']}
          >
            Quick Apply&nbsp;
            <i className="material-icons">arrow_downward</i>
          </Button>
        </div>
        <Img src={backbone} alt="D&D Transportation Services" />
      </Zone>

      <Container>
        {/* <PageTitle>Drivers</PageTitle> */}

        <SidebarLayout>
          <div>
            <h2>Culture is Everything</h2>
            <LargeP>At D&D Transportation we believe in providing a work-life balance that meets individual goals while providing competitive pay packages and robust benefits.</LargeP>
            <p>It is our priority to provide a warm and welcoming culture that all of our employees can embrace. For over 40 years, D&D has been serving clients with quality, safe and dependable transportation while providing sustainable careers with financial stability. Drivers are our number one asset, and we believe that investing in their future is investing in the future of America.</p>
            <h4>Competitive Compensation & Benefits</h4>
              <p>We take care of our own and offer top truck driver pay.</p>
              <BenefitsSidebarList>
              <BenefitsItem>
                  <i className="material-icons">loyalty</i> All Miles Paid (not just loaded miles)
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">local_atm</i> Weekly Pay via Direct Deposit
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">attach_money</i> Layover, Detention, Stop and Pick, Shuttle, and Breakdown Pay on Top of Mileage
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">explore</i> Paid orientation and travel expenses
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">favorite</i> Health Insurance, Medical, Dental, Vision, and Prescription with Generous Employer Contributions
                </BenefitsItem>
              </BenefitsSidebarList>
            <h4>On the Road</h4>
              <p>As a Family Owned and in Business for over 40 Years, we know job satisfaction goes beyond compensation. Here are just a few bonuses we offer for work-life balance and efficiencies on the road.</p>
              <BenefitsSidebarList>
                <BenefitsItem>
                  <i className="material-icons">home</i> Flexible Home Time
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">pan_tool</i> No Touch Freight
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">rv_hookup</i> Drop and Hook Freight
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">pets</i> Immediate Pet and Rider Policy
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">line_weight</i> Prepass for Weight Stations and Roll-through Tolls
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">receipt</i> E-Logs and Electronic Scanning for Paperwork
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">local_shipping</i> Upgraded Latest Models - Freightliner Cascadia and Utility Trailers with Carrier Reefer Units
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">arrow_forward</i> Dedicated Routes
                </BenefitsItem>
              </BenefitsSidebarList>
              <h4>Requirements & Qualifications</h4>
              <p>If you meet our simple and straightforward requirements, you can be part of the D&D family.</p>
              <BenefitsSidebarList>
                <BenefitsItem>
                  <i className="material-icons">cake</i> 23 years of age
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">account_box</i> Current Class A CDL
                </BenefitsItem>
                <BenefitsItem>
                  <i className="material-icons">date_range</i> 8+ months experience
                </BenefitsItem>
              </BenefitsSidebarList>
          </div>
          
          <div
            className="benefits-sidebar"
            style={{
              background: 'white',
              height: '100%',
              paddingBottom: '2rem',
            }}
          >
            <h4>MONEY OUR DRIVERS DESERVE</h4>
            <p>We believe that relationships are more important than the bottom line. If our priority is people, there will always be profit. Earn top pay and experience a company culture that makes a difference.</p>

            <h4 style={{ marginTop: '2rem' }} id="quickapply">Quick Apply</h4>
            <QuickApply />

            
            <p style={{ marginTop: '2rem' }}><strong>Have additional questions? Give us a call:</strong></p>
            <PhoneHome to="tel:+18002237671" rel="nofollow">(800) 223-7671</PhoneHome>
          </div>
        </SidebarLayout>
      </Container>
      <EquipmentContainer>
        <div className="equipdescript">
          <h2>Top of the Line Equipment</h2>
          <p>
          We run and maintain the best modern equipment in the industry with safety and reliability top-of-mind. Our tractor and trailer fleet has an average age of fewer than 2 years, and we continually cycle older equipment. Our fleet is California Air Resources Board (CARB) verified for Certified Clean Idle. As a registered EPA SmartWay™ Transportation Partner, we reduce fuel use with increased efficiency. We also provide drivers’ lane departure, stability control, and hand-braking so our drivers can be as safe as possible on the road.
          </p>
        </div>
      </EquipmentContainer>
        
      <Gallery photos={photos} />

      <Zone
        modifiers={['darkOverlay', 'center', 'short']}
        left
        hero
        image={hero}
      >
        <div className="zone-content">
          <Testimonial>
            <p>
              Trucks, and the companies that run them, are all mostly the same.
              What sets D&D above the rest is the people. They care about you
              and they care about their customers.
            </p>
            <cite>Raymond Brooks, D&D Driver for 22 years</cite>
          </Testimonial>
        </div>
      </Zone>
    </Layout>
  )
}

export default DriversPage

export const BenefitHighlights = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  &::after {
    content: '';
    flex: 0 0 24%;
  }
  position: relative;
  margin: 0 0 2rem 0;
  margin-top: -80px;
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  /* transition: background 0.2s; */
  flex: 0 0 100%;
  ${above.md`
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
  `};
  ${above.lg`
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  `};
`
export const BenefitFeature = styled.div`
  position: relative;
  margin: 0;
  background: white;
  border: 1px dotted red;
  height: 100%;
`
export const ReverseBanner = styled.h4`
  color: ${darkGrey} !important;
`
export const Img = styled.img`
  display: none;
  ${above.md`
    display: flex;
    position: absolute;
    max-width: 340px;
    top: 15rem;
    right: 3rem;
  `}
  ${above.lg`
    position: absolute;
    top: 2rem;
    right: 6rem;
    max-width: 340px;
  `}
`
const Testimonial = styled.blockquote`
  p {
    font-size: 1.35rem;
    line-height: 1.85rem;
  }
  cite,
  cite a {
    font-size: 1.15rem;
  }
`
const PhoneHome = styled(Link)`
  ${sansCondBold}
  font-size: 1.5rem;
  color: ${darkGrey};
  background-color: ${primary};
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.3s ease border;
  display: inline-block;
  margin-bottom: 1rem;
  &:hover,
  &.active {
    color: ${darkGrey};
    text-decoration: underline;
  }
`
const EquipmentContainer = styled.section`
  background: ${secondary};
  .equipdescript {
    width: 90%;
    text-align: center;
    margin: 3rem auto;
    color: white;
    ${above.md`
    width: 80%;
  `}
  ${above.lg`
    width: 50%
  `}
  }
`